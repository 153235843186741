import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import api from '../util/api';
import _ from 'lodash';
import Promise from 'bluebird';
import moment from 'moment';
import { Cookies } from 'react-cookie';
import Modal from '@material-ui/core/Modal';
const cookie = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '26pt',
  },
  content: {
    fontSize: '18pt',
  },
  fbSSO: {
    margin: 'auto',
    textAlign: 'center',
  },
  textField: {
    minWidth: '100%',
  },
  downloadIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'black',
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const exportData = (objectData, fileName = 'export') => {
  let filename = `${fileName}.json`;
  let contentType = 'application/json;charset=utf-8;';
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))],
      { type: contentType },
    );
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement('a');
    a.download = filename;
    a.href =
      'data:' +
      contentType +
      ',' +
      encodeURIComponent(JSON.stringify(objectData, null, 2));
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const responseFacebook = async (accessToken, igUserId) => {
  if (igUserId) {
    let data = [];

    const username = await api
      .getInstagramUser(accessToken, igUserId)
      .then(result => {
        data = [...data, { name: 'basic', values: [{ value: result }] }];
        return result.username;
      })
      .catch(console.error);

    await api
      .getInstagramInsightAudience(accessToken, igUserId)
      .then(result => (data = [...data, ...result.data]))
      .catch(console.error);

    await api
      .getInstagramInsight(accessToken, igUserId)
      .then(result => (data = [...data, ...result.data]))
      .catch(console.error);

    await api
      .getInstagramMediaList(accessToken, igUserId)
      .then(async result => {
        const mediaIds = _.map(result.data, 'id');
        const mediaDetail = await Promise.map(
          mediaIds,
          async id => {
            const info = await api.getInstagramMedia(accessToken, id);
            const comment = await api.getInstagramMediaComments(
              accessToken,
              id,
            );
            return { id, info, comment };
          },
          { concurrency: 5 },
        );
        if (mediaDetail) {
          data = [...data, { name: 'media', values: [{ value: mediaDetail }] }];
        }
      });

    if (data) {
      //   exportData(data, username);
    }
    return data;
  } else {
    return null;
  }
};

const responseFacebookSearch = async (accessToken, igUserId, tag) => {
  if (igUserId && tag) {
    let data = [];

    // const username = await api
    //   .getInstagramUser(accessToken, igUserId)
    //   .then(result => {
    //     data = [...data, { name: 'basic', values: [{ value: result }] }];
    //     return result.username;
    //   })
    //   .catch(console.error);

    await api
      .getInstagramTags(accessToken, igUserId, tag)
      .then(result => (data = [...data, ...result.data]))
      .catch(console.error);

    if (data) {
      //   exportData(data, username);
    }
    return data;
  } else {
    return null;
  }
};

export default function Landing() {
  const classes = useStyles();
  const igAccessToken = cookie.get('accessToken');
  const igUserId = cookie.get('igUserId');
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [fetchData, setData] = React.useState('');
  const [tag, setTag] = React.useState('');
  const [fetchSearch, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchIgData = async () => {
    setLoading(true);
    const result = await responseFacebook(igAccessToken, igUserId);
    console.log(result);
    setLoading(false);
    if (_.get(result, '[0].values[0].value.error')) {
      handleOpen();
      return 'Error with grant permissions';
    }

    return JSON.stringify(result, null, 2);
  };

  const fetchIgSearch = async () => {
    console.log(tag);
    setLoading(true);
    const result = await responseFacebookSearch(igAccessToken, igUserId, tag);
    console.log(result);

    setLoading(false);
    return JSON.stringify(result, null, 2);
  };

  const downloadData = async () => {
    const now = moment().unix();
    if (fetchData) {
      exportData(JSON.parse(fetchData), now);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Error</h2>
      <p id="simple-modal-description">
        {'Please bind your Instagram account to Facebook Page.\n'}
      </p>
      <a href={'https://www.facebook.com/business/help/898752960195806'}>
        {'How to bind your Instagram account to Facebook Page'}
      </a>
    </div>
  );

  return (
    <div className="my-5 container justify-content-center text-center">
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>

      <p className={`text-center my-5 ${classes.title}`}>
        Instagram Insight Exporter
      </p>
      <p className={`text-center my-2  ${classes.content}`}>
        This is an easy way to export raw data of your Instagram insights.
      </p>
      <p className={`text-center my-2  ${classes.content}`}>---</p>

      {/*<div className="my-5">*/}
      {/*  {igAccessToken ? (*/}
      {/*    <div>*/}
      {/*      <div>*/}
      {/*        <Button*/}
      {/*          variant="contained"*/}
      {/*          color="primary"*/}
      {/*          className="mx-5"*/}
      {/*          onClick={async () => {*/}
      {/*            // setSearch('');*/}
      {/*            setSearch(await fetchIgSearch());*/}
      {/*          }}*/}
      {/*          disabled={loading}*/}
      {/*          // disabled={!igAccessToken || loading}*/}
      {/*        >*/}
      {/*          {'Search Tag'}*/}
      {/*        </Button>*/}
      {/*      </div>*/}

      {/*      <div>*/}
      {/*        <TextField*/}
      {/*          id="filled-basic"*/}
      {/*          label="Tag"*/}
      {/*          variant="standard"*/}
      {/*          value={tag}*/}
      {/*          onChange={e => setTag(e.target.value)}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      {/*    <div></div>*/}
      {/*  )}*/}
      {/*</div>*/}

      <div className="my-5">
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (igAccessToken) {
                setData('');
                setData(await fetchIgData());
              } else {
                history.push('/login');
              }
            }}
            disabled={loading}
            // disabled={!igAccessToken || loading}
          >
            {igAccessToken ? 'Fetch Data' : 'Login'}
          </Button>

          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>

      {igAccessToken ? (
        <div>
          <div className="text-left justify-content-end mx-5">
            <GetAppOutlinedIcon
              className={classes.downloadIcon}
              onClick={downloadData}
            />
          </div>
          <div>
            <TextField
              className={classes.textField}
              id="outlined-basic"
              label=""
              variant="outlined"
              multiline={true}
              value={fetchData}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
