import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Landing from './pages/Landing';
import Terms from './pages/Terms';
import Login from './pages/Login';
import TitleBar from './modules/TitleBar';
import Footer from './modules/Footer';

export default function App() {
  return (
    <div>
      <TitleBar />
      <div>
        <Router>
          <div>
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/login" exact component={Login} />
              <Route path="/logout" component={Login} />
              <Route path="*" component={Landing} />
            </Switch>
          </div>
        </Router>
      </div>
      <Footer />
    </div>
  );
}
