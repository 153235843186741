import React from 'react';
import './footer.css';
import mailIcon from '../assets/mail_18dp.png';

export default function footer() {
  return (
    <div>
      <footer className="bg-dark fixed-bottom">
        {/*<div className="container">*/}
        <div className="footerText">
          <span className="text-white copyRightText">
            Copyright &copy; 2021 Influenxio Limited. All rights reserved.
          </span>
          <a href={`mailto:ryan.chang@influenxio.info`} className="mailIcon">
            <img src={mailIcon} className="mailIcon" alt="" />
          </a>
        </div>
      </footer>
    </div>
  );
}
