import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo from '../assets/logo171*136.png';
import { Cookies } from 'react-cookie';
const cookie = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: '#1c3056',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  logo: { height: 40 },
  logoText: {
    color: '#1c3056',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  bar: {
    background: '#cccccc',
  },
  login: {
    color: '#1c3056',
  },
}));
export default function TitleBar() {
  const igUserName = cookie.get('igUserName');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Toolbar>
          <Link href="/" style={{ textDecoration: 'none' }}>
            <img src={logo} className={classes.logo} alt="" />
          </Link>

          <Typography className={classes.title} href="/">
            <Link href="/" style={{ textDecoration: 'none' }}>
              {'Influenxio Limited'}
            </Link>
          </Typography>
          <Button className={`mx-2 ${classes.login}`} href="/terms">
            Terms
          </Button>
          <Button className={`mx-2 ${classes.login}`} href="/login">
            {igUserName ? igUserName : 'LogIn'}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
