const axios = require('axios');
const { get } = require('lodash');
const moment = require('moment');

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
  timeout: 30000,
  baseURL: 'https://graph.facebook.com/v12.0',
});

const getInstagramUser = async (token, igUserId) => {
  return client
    .get(`/${igUserId}`, {
      params: {
        access_token: token,
        fields:
          'biography,id,ig_id,followers_count,follows_count,media_count,name,profile_picture_url,username,website',
      },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramMediaList = async (token, igUserId) => {
  return client
    .get(`/${igUserId}/media`, {
      params: { access_token: token },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramMedia = async (token, igMediaId) => {
  return client
    .get(`/${igMediaId}`, {
      params: {
        access_token: token,
        fields:
          'caption,comments_count,id,ig_id,like_count,media_type,media_url,owner,permalink,shortcode,thumbnail_url,timestamp,username',
      },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramMediaComments = async (token, igMediaId) => {
  return client
    .get(`/${igMediaId}/comments`, {
      params: {
        access_token: token,
      },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramBusinessAccount = async token => {
  return client
    .get('/me/accounts', {
      params: { fields: 'instagram_business_account', access_token: token },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramInsightAudience = async (token, igUserId) => {
  return client
    .get(`/${igUserId}/insights`, {
      params: {
        metric:
          'audience_city,audience_country,audience_gender_age,audience_locale,online_followers',
        period: 'lifetime',
        // since: now - 86400 * 1,
        // until: now - 86400 * 0,
        access_token: token,
      },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramInsight = async (token, igUserId) => {
  const now = moment().unix();
  return client
    .get(`/${igUserId}/insights`, {
      params: {
        metric: 'follower_count,impressions,reach',
        period: 'day',
        since: now - 86400 * 30,
        until: now,
        access_token: token,
      },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

const getInstagramTags = async (token, igUserId, tag) => {
  return client
    .get(`/ig_hashtag_search?user_id=${igUserId}&q={tag}`, {
      params: {
        // metric:
        //   'audience_city,audience_country,audience_gender_age,audience_locale,online_followers',
        // period: 'lifetime',
        // since: now - 86400 * 1,
        // until: now - 86400 * 0,
        access_token: token,
      },
    })
    .then(response => {
      return get(response, 'data');
    })
    .catch(e => {
      const error = get(e, 'response.data');
      if (error) {
        return error;
      }
      return e;
    });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getInstagramBusinessAccount,
  getInstagramInsightAudience,
  getInstagramInsight,
  getInstagramUser,
  getInstagramMedia,
  getInstagramMediaList,
  getInstagramMediaComments,
  getInstagramTags,
};
