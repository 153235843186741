import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo from '../assets/logo171*136.png';
import Modal from '@material-ui/core/Modal';
import api from '../util/api';
import _ from 'lodash';
import fp from 'lodash/fp';
import FacebookLogin from 'react-facebook-login';
import { Cookies } from 'react-cookie';
const cookie = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: '#1c3056',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  logo: { height: 40 },
  logoText: {
    color: '#1c3056',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  bar: {
    background: '#cccccc',
  },
  login: {
    color: '#1c3056',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Login() {
  const igAccessToken = cookie.get('accessToken');
  const igUserId = cookie.get('igUserId');
  const igUserName = cookie.get('igUserName');
  const history = useHistory();
  const classes = useStyles();
  const [isLogin, setLoginState] = useState(igAccessToken ? true : false);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const login = async response => {
    if (response.accessToken) {
      const igUserId = await api
        .getInstagramBusinessAccount(response.accessToken)
        .then(({ data }) => _.find(data, 'instagram_business_account'))
        .then(fp.get('instagram_business_account.id'))
        .catch(console.error);

      if (igUserId) {
        cookie.set('accessToken', response.accessToken, { path: '/' });
        cookie.set('igUserName', response.name, { path: '/' });
        cookie.set('igUserId', igUserId, { path: '/' });
        setLoginState(true);
        history.push('/');
        window.location.reload();
      } else {
        //    handleOpen();
        cookie.set('accessToken', response.accessToken, { path: '/' });
        cookie.set('igUserName', response.name, { path: '/' });
        cookie.set('igUserId', igUserId, { path: '/' });
        setLoginState(true);
        history.push('/');
        window.location.reload();
      }
    }
  };

  const logout = () => {
    cookie.remove('accessToken', { path: '/' });
    cookie.remove('igUserName', { path: '/' });
    cookie.remove('igUserId', { path: '/' });
    setLoginState(false);
    history.push('/');
    window.location.reload();
  };

  const logOutDiv = () => {
    return (
      <div style={{ margin: 'auto', textAlign: 'center', paddingTop: '2em' }}>
        <div>
          <Button onClick={logout} variant="contained" color="primary">
            Log Out
          </Button>
        </div>
      </div>
    );
  };

  const logInDiv = () => {
    return (
      <div style={{ margin: 'auto', textAlign: 'center', paddingTop: '2em' }}>
        <h1 className="my-4 "> LOGIN </h1>
        <FacebookLogin
          className="my-5"
          appId="1820713568220950"
          autoLoad={false}
          // scope="pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,public_profile"
          scope="pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,public_profile"
          fields="name,email,picture"
          callback={login}
        />
      </div>
    );
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Error</h2>
      <p id="simple-modal-description">
        {'Please bind your Instagram account to Facebook Page.\n'}
      </p>
      <a href={'https://www.facebook.com/business/help/898752960195806'}>
        {'How to bind your Instagram account to Facebook Page'}
      </a>
    </div>
  );

  if (window.location.pathname === '/logout') {
    logout();
  }

  return (
    <div className={classes.root}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>

      <div>{isLogin ? logOutDiv() : logInDiv()}</div>
    </div>
  );
}
