import React from 'react';

export default function Terms() {
  return (
    <div className="my-5 container  ">
      <div>
        <section>
          <div className="en section_container" section-language="en">
            <div id="md_en" data-target="out_en" className="agreement_md">
              <h1>Privacy Policy</h1>
              <div>
                <meta
                  content="text/html; charset=utf-8"
                  httpEquiv="Content-Type"
                />
                <meta content="text/css" httpEquiv="Content-Style-Type" />
                <p>
                  <span className="s1">We don’t store your data.</span>
                </p>

                <p>
                  <span className="s1">
                    No data within the website or accessed by the website is
                    ever transmitted to the developer, or to anyone else.
                  </span>
                </p>

                <p>
                  <span className="s1">
                    We physically can't. We have nowhere to store it. We don't
                    even have a server database to store it.
                  </span>
                </p>
              </div>
            </div>
            <div id="out_en" className="agreement_output"></div>
          </div>
        </section>

        <footer>
          <div className="container">Privacy Policy for Influenxio Limited</div>
        </footer>
      </div>
    </div>
  );
}
